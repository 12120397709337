import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "upvotes", "downvotes", "upvote", "downvote" ]
  static values = {
    upvotes: Number,
    downvotes: Number,
    current: Number
  }

  upvote() {
    if (window.disable_feedback == undefined || window.disable_feedback == false) {
      if (this.currentValue == 0) {
        var url = '/quality/rate'
        url += `?id=${window.question}&rating=up&location=${window.location.href}`
        fetch(url)
        .then(response => response.json())
        .then(data => {
          this.currentValue = 1;
          this.upvotesValue = this.upvotesValue + 1;
          this.upvotesTarget.innerText = this.upvotesValue;
          this.upvoteTarget.classList.add('voted');
          this.upvoteTarget.querySelector('i').classList.remove('far');
          this.upvoteTarget.querySelector('i').classList.add('fas');
          document.querySelector('.mini-dashboard #points #value').innerText = data.points;
        });

      } else if (this.currentValue == 1) {
        this.revert();
      }
    }
  }

  downvote() {
    if (window.disable_feedback == undefined || window.disable_feedback == false) {
      if (this.currentValue == 0) {
        var url = '/quality/rate'
        url += `?id=${window.question}&rating=down&location=${window.location.href}`
        fetch(url)
        .then(response => response.json())
        .then(data => {
          this.currentValue = -1;
          this.downvotesValue = this.downvotesValue + 1;
          this.downvotesTarget.innerText = this.downvotesValue;
          this.downvoteTarget.classList.add('voted');
          this.downvoteTarget.querySelector('i').classList.remove('far');
          this.downvoteTarget.querySelector('i').classList.add('fas');
          document.querySelector('.mini-dashboard #points #value').innerText = data.points;
        });
      } else if (this.currentValue == -1) {
        this.revert();
      }
    }
  }

  revert() {
    var url = '/quality/revert'
    url += `?id=${window.question}&location=${window.location.href}`
    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (this.currentValue == 1) {
        this.upvotesValue = this.upvotesValue - 1;
        this.upvotesTarget.innerText = this.upvotesValue;
        this.upvoteTarget.classList.remove('voted');
        this.upvoteTarget.querySelector('i').classList.remove('fas');
        this.upvoteTarget.querySelector('i').classList.add('far');
      } else {
        this.downvotesValue = this.downvotesValue - 1;
        this.downvotesTarget.innerText = this.downvotesValue;
        this.downvoteTarget.classList.remove('voted');
        this.downvoteTarget.querySelector('i').classList.remove('fas');
        this.downvoteTarget.querySelector('i').classList.add('far');
      }
      this.currentValue = 0;
      document.querySelector('.mini-dashboard #points #value').innerText = data.points;
    });
  }
}
